import image1 from "../assets/t-image1.png";
import image2 from "../assets/t-image2.jpg";
import image3 from "../assets/t-image3.jpg";

export const testimonialsData = [
  {
    image: image1,
    review:
      "I made the right choice by choosing the Fitclub and by choosing the right plan and program I already achieved my ideal body!",
    name: 'KALU CHIGOZIE',
    status : 'COACH'
  },
  {
    image: image2,
    review: 'The fit club is the best place to keep your fitness level high especially if you are always sitting while working.',
    name: 'ISAAC MADU',
    status: 'SOFTWARE ENG'
  },
  {
    image : image3,
    review:'As a medical practiconer, keeping fit is a little bit difficult for me but with fit club, i have never been more healthier. ',
    name: 'PRAISE UDEH',
    status: "Nurse"
  }
];
